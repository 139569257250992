/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import axios from "axios";
import {shallowEqual, useSelector} from "react-redux";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useHistory } from "react-router-dom";
import {BACKEND_URL} from "../../modules/auth/redux/AuthCRUD";

const Ancare = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [validateErrorMsgArr, setValidateErrorMsgArr] = useState([])

  const [name, setName] = useState('')
  const [phone, setPhone] = useState('')
  const [age, setAge] = useState('TRE_TRUNG')
  const [life, setLife] = useState('LANH_MANH')
  const [general, setGeneral] = useState('TRAN_DAY')
  const [sick, setSick] = useState([])
  const [users, setUsers] = useState([])

  console.log({
    name, phone, age, life, sick
  })

  const accessToken = useSelector(({auth}) => auth.accessToken, shallowEqual)

  useEffect(() => {
    axios.post(`${BACKEND_URL}/ancare-list`, {
      access_token: accessToken,
    }).then(res => res.data)
      .then(resData => {
        setIsLoading(false)
        if (resData.error_code === 1) {
          setUsers(resData.data.ancare)
        } else {
          setValidateErrorMsgArr([resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên'])
        }
      })
  }, [])

  const handleConfirm = () => {
    setIsLoading(true)
    setValidateErrorMsgArr([])
    axios.post(`${BACKEND_URL}/ancare`, {
      access_token: accessToken,
      name: name,
      phone: phone,
      age: age,
      life: life,
      general: general,
      sick: sick.toString(),
    }).then(res => res.data)
      .then(resData => {
        setIsLoading(false)
        if (resData.error_code === 1) {
          toast("Tạo tài khoản ancare thành công", {
            onClose: () => {
              window.location.replace('/ancare')
            }
          })
        } else {
          setValidateErrorMsgArr([resData.message ?? 'Đã xảy ra lỗi, vui lòng liên hệ quản trị viên'])
        }
      })
  }

  return (
    <>
      <ToastContainer />
      <div className="card card-custom">
        <div className="card-header">
          <h3 className="card-title">
            Ancare
          </h3>
        </div>
        <form>
          <div className="card-body">
            <div className="form-group">
              <label>Họ và tên <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="" value={name} onChange={(e) => setName(e.target.value)}/>
            </div>
            <div className="form-group mt-5">
              <label>Số điện thoại <span className="text-danger">*</span></label>
              <input type="text" className="form-control" placeholder="" value={phone} onChange={(e) => setPhone(e.target.value)}/>
            </div>

            <div className="form-group mt-5">
              <label>Độ tuổi <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={age}
                  onChange={(e) => {setAge(e.target.value)}}
                  className="form-control"
                >
                  <option value='TRE_TRUNG'>TRẺ TRUNG</option>
                  <option value='TRUNG_NIEN'>TRUNG NIÊN</option>
                  <option value='KHONG_CON_TRE'>KHÔNG CÒN TRẺ</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5">
              <label>Cách sống <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={life}
                  onChange={(e) => {setLife(e.target.value)}}
                  className="form-control"
                >
                  <option value='LANH_MANH'>LÀNH MẠNH, TÍCH CỰC</option>
                  <option value='CONG_VIEC'>QUÁ TẬP TRUNG CÔNG VIỆC</option>
                  <option value='NUONG_CHIEU'>NUÔNG CHIỀU BẢN THÂN</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5">
              <label>Tình trạng chung <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={general}
                  onChange={(e) => {setGeneral(e.target.value)}}
                  className="form-control"
                >
                  <option value='TRAN_DAY'>TRÀN ĐẦY SỨC SỐNG</option>
                  <option value='VAN_TRAN_DAY'>VẪN TRÀN ĐẦY SỨC SỐNG</option>
                  <option value='CANG_THANG'>CĂNG THẲNG LO LẮNG</option>
                  <option value='LO_LANG'>CĂNG THẲNG LO LẮNG</option>
                  <option value='CAI_THIEN'>CẢI THIỆN SỨC KHỎE NGAY</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-5">
              <label>Nguy cơ bệnh <span className="text-danger">*</span></label>
              <div className="form-group">
                <select
                  value={sick}
                  onChange={(e) => {
                    const { options } = e.target;
                    const selectedValues = [];

                    // Loop through options to get selected values
                    for (let i = 0, l = options.length; i < l; i++) {
                      if (options[i].selected) {
                        selectedValues.push(options[i].value);
                      }
                    }

                    setSick(selectedValues);
                  }}
                  className="form-control"
                  multiple={true}
                  style={{ height: '450px' }}
                >
                  <option value='TIM_MACH' className='mb-3'>VẤN ĐỀ TIM MẠCH</option>
                  <option value='HO_HAP' className='mb-3'>VẤN ĐỀ HÔ HẤP</option>
                  <option value='TIEU_HOA' className='mb-3'>VẤN ĐỀ TIÊU HÓA</option>
                  <option value='CHUYEN_HOA' className='mb-3'>VẤN ĐỀ CHUYỂN HÓA</option>
                  <option value='TIET_NIEU' className='mb-3'>VẤN ĐỀ TIẾT NIỆU</option>
                  <option value='CO_XUONG_KHOP' className='mb-3'>VẤN ĐỀ CƠ XƯƠNG KHỚP</option>
                  <option value='DA_LIEU' className='mb-3'>VẤN ĐỀ DA LIỄU</option>
                  <option value='THAN_KINH' className='mb-3'>VẤN ĐỀ HỆ THẦN KINH</option>
                  <option value='THI_LUC' className='mb-3'>VẤN ĐỀ THỊ LỰC</option>
                  <option value='THINH_LUC' className='mb-3'>VẤN ĐỀ THÍNH LỰC</option>
                  <option value='MIEN_DICH' className='mb-3'>VẤN ĐỀ HỆ MIỄN DỊCH</option>
                  <option value='LAO_HOA' className='mb-3'>VẤN ĐỀ LÃO HÓA</option>
                  <option value='CANG_THANG' className='mb-3'>VẤN ĐỀ CĂNG THẲNG</option>
                  <option value='LUOI_VAN_DONG' className='mb-3'>VẤN ĐỀ LƯỜI VẬN ĐỘNG</option>
                </select>
              </div>
            </div>

            <div className="form-group mt-10">
              {validateErrorMsgArr.map((validateErrorMsg, index) => <p className="text-danger" key={index}>{validateErrorMsg}</p>)}
            </div>
          </div>
          <div className="card-footer">
            <button type="button" className="btn btn-primary mx-2 " disabled={isLoading} onClick={() => handleConfirm()}>Xác nhận</button>
            <button type="reset" className="btn btn-secondary mx-2">Hủy bỏ</button>
          </div>
        </form>
        <div className='card-body'>
          <table className="table table-bordered table-responsive">
            <thead>
            <tr>
              <th>Tên</th>
              <th>SĐT</th>
              <th>Code</th>
              <th>Is_scan</th>
              <th>Age</th>
              <th>Life</th>
              <th>General</th>
              <th>Sick</th>
            </tr>
            </thead>
            <tbody>
            {users.length > 0 && users.map((user) =>
              <tr>
                <td>{user.name}</td>
                <td>{user.phone}</td>
                <td>{user.code}</td>
                <td>{user.is_scan == 1 ? 'Đã quét' : 'Chưa quét'}</td>
                <td>{user.age}</td>
                <td>{user.life}</td>
                <td>{user.general}</td>
                <td>{user.sick}</td>
              </tr>
            )}


            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

export {Ancare}
